


import React, { useState } from 'react';

import '../../css/Common.css';
import PageHeader from './../Util/PageHeader';
import GradButton from './../Util/GradButton';
import '../../css/recognition.css'

const Recognition = (props) => {
  return (
	
    <div className="recognition">
		<PageHeader title="Awards and Recognition" subtitle="Or global recognition and awards" />
		<div className="sec1">
            <div className='regwrapper'>
                <h2 className="headingSmall">News Recognition</h2>
                <br /><br />
                <div className="row leftrightmargin">
                    <div class="col-6 nopad padright centervert">
                        <h2 className="subheading1">Bloomberg</h2>
                        <p className="subheading2">Upcoming interview on Bloomberg Technologies Segment, where members from our board and MAD teams will discuss the technology behind ParkinSense, and its future. </p>
                    </div>
                    <div class="col-6 nopad">
                        <div className="imagewrapper">
                            <img  className="img" src="https://1000logos.net/wp-content/uploads/2017/08/Bloomberg-logo.png"></img>
                        </div>
                    </div>
                </div>

                <div className="row leftrightmargin">
                <div class="col-6 nopad">
                    <div className="imagewrapper">
                        <img  className="img" src="https://earthbabystore.com/wp-content/uploads/2021/01/wfaa-480x252.png"></img>
                    </div>
                </div>
                    <div class="col-6 nopad padleft centervert">
                        <h2 className="subheading1">ABC</h2>
                        <p className="subheading2">Upcoming interview and news article by ABC Dallas, News 8, with our board.</p>
                    </div>
                </div>
                <div className="row leftrightmargin">
                    <div class="col-6 nopad padright centervert">
                        <h2 className="subheading1">Forbes 30 under 30 Finalist</h2>
                        <p className="subheading2">Our President, Pulkith, was recognized as a Forbes 30 under 30 Finalist for the 2022 Science Category.</p>
                    </div>
                    <div class="col-6 nopad">
                        <div className="imagewrapper">
                            <img  className="img" src="https://dg6qn11ynnp6a.cloudfront.net/wp-content/uploads/2021/12/02172623/bevnet_forbes_30under30_opengraph.jpg"></img>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div className="sec2">
            <div className='regwrapper'>
            <h2 className="headinggrad">Award-Winning Solutions</h2>
                <h2 className="headingSmall">Awards</h2>
                <br /><br />
                <div className="row leftrightmargin">
                    <div class="col-6 nopad padright centervert">
                        <h2 className="subheading1">Young Tycoons Business Challenge</h2>
                        <p className="subheading2">First place out of 12,000+ teams in the world's largest business competition and startup incubator. Recieve mentoring from Harvard Student Agencies.</p>
                    </div>
                    <div class="col-6 nopad">
                        <div className="imagewrapper">
                            <img  className="img" src={require('../../assets/awards/ytbc.png')}></img>
                        </div>
                    </div>
                </div>

                <div className="row leftrightmargin">
                <div class="col-6 nopad">
                    <div className="imagewrapper">
                        <img  className="img" src={require('../../assets/awards/diamond.png')}></img>
                    </div>
                </div>
                    <div class="col-6 nopad padleft centervert">
                        <h2 className="subheading1">Diamond Challenge</h2>
                        <p className="subheading2">Top 30 teams out of 750+ in the Social Innovation Track of the Diamond Challenge - the largest high school business competition in the US</p>
                    </div>
                </div>

                <div className="row leftrightmargin">
                    <div class="col-6 nopad padright centervert">
                        <h2 className="subheading1">Conrad Challenge</h2>
                        <p className="subheading2">Top section of teams in the Health Category of the 2022 Conrad Challenge</p>
                    </div>
                    <div class="col-6 nopad">
                        <div className="imagewrapper">
                            <img  className="img" src={require('../../assets/awards/conrad.jpeg')}></img>
                        </div>
                    </div>
                </div>

                <div className="row leftrightmargin">
                <div class="col-6 nopad">
                        <div className="imagewrapper">
                            <img  className="img" src={require('../../assets/awards/blueocean.jpeg')}></img>
                        </div>
                    </div>
                    <div class="col-6 padleft centervert">
                        <h2 className="subheading1">Blue Ocean Challenge</h2>
                        <p className="subheading2">Semifinialist in one of the world's largest business competitions.</p>
                    </div>
                </div>
            </div>


        </div>
    </div>
  );
}


export default Recognition ;