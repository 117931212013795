
import React, { useState } from 'react';
import '../../css/Team.css';
import PageHeader from './../Util/PageHeader';
const Team = () => {

	let exec = [], research = [], mad = [], eo = []
	exec.push(<StaffPerson name="Pulkith Paruchuri" title="President, Chief Executive Officer, VP Research" image="https://media.licdn.com/dms/image/C4E03AQGW2M9Jtw8asw/profile-displayphoto-shrink_400_400/0/1660550290903?e=1679529600&v=beta&t=ExsTGlKB1aDu-OsjI6RqO23dHDH2b-feTIivpFg06sU" />)
	exec.push(<StaffPerson name="Diya Kumar" title="Chief Technology Officer, Chief Data Offier, VP MAD" image="https://media.licdn.com/dms/image/D4E03AQEKbAzPy-1gLA/profile-displayphoto-shrink_400_400/0/1670556035312?e=1679529600&v=beta&t=1MB3HEOvpuEbIiH045-IAxiC6dtjwUZyDRlJeavQbeg"/>)
	exec.push(<StaffPerson name="Pranav Kulkarni" title="Chief Operating Officer, VP Enterprise Operations" image="https://m.media-amazon.com/images/S/amzn-author-media-prod/r4ii7cial5q0skk4ad008niht4._SY429_CR0%2C0%2C429%2C429_.jpg"/>)

	research.push(<StaffPerson name="Vivian Nyugen" title="Project Lead, Reseacher" image="https://media.licdn.com/dms/image/C5603AQFbvZDK1Te2ww/profile-displayphoto-shrink_400_400/0/1655071154332?e=1679529600&v=beta&t=WHgO6xDLpyApUQlezAfCMxnl_vb22d1F1cAKdWttF54"/>)
	research.push(<StaffPerson name="Shreya Sunil " title="Project Lead, Reseacher" image="https://media.licdn.com/dms/image/D4E03AQF9UzGlt-RYNA/profile-displayphoto-shrink_400_400/0/1673030923175?e=1679529600&v=beta&t=SoetXpTtXLs7WpSHbnz1ZTxFUcfkIhvS6JATbWmKJgU"/>);
	research.push(<StaffPerson name="Peter Bennett " title="Project Lead, Reseacher" image="https://media.licdn.com/dms/image/D5603AQHuQoopeFioDg/profile-displayphoto-shrink_400_400/0/1672946800485?e=1679529600&v=beta&t=nOQ0dA79jLXsyGceHu0mU584S8zXIYz0ANc4TGocByM" />)
	research.push(<StaffPerson name="Kashish Sawant " title="Project Lead, Reseacher" image="https://media.licdn.com/dms/image/D5603AQHSrRbx83EkMw/profile-displayphoto-shrink_400_400/0/1670976424352?e=1679529600&v=beta&t=YOUHXIptsb8TuRdSiqRNFLbjd_5nscYnurO6u_tCw7o"/>);
	research.push(<StaffPerson name="Sharv Save" title="Project Lead, Reseacher" />)
	research.push(<StaffPerson name="Pooja Kedia" title="Vice Project Lead, Reseacher" />)


  
	
	mad.push(<StaffPerson name="Alan Ma" title="Model Development Lead" image="https://media.licdn.com/dms/image/D5603AQHj3pQ1to2kGw/profile-displayphoto-shrink_400_400/0/1666553420142?e=1679529600&v=beta&t=-lUjDkaY058ovzyBYa7jO9mJjZI8eQu1zYHduZ2ITek"/>)
	mad.push(<StaffPerson name="Luke Bousfield" title="Mobile Development Lead" image="https://media.licdn.com/dms/image/D5603AQGrYOg65V38yQ/profile-displayphoto-shrink_400_400/0/1674002496127?e=1679529600&v=beta&t=In41Vhopsp8HbAvs_fvNFVhma58WTJE9j-oP5AyjVOc"/>)
	mad.push(<StaffPerson name="Maadhav Kothuri" title="Web Development Lead" image="https://media.licdn.com/dms/image/C4D03AQGBV0xWSLDjPA/profile-displayphoto-shrink_400_400/0/1630030570294?e=1679529600&v=beta&t=ujAwFDkbDVSHTvXFNRQSMv1vW270cMmTvWSVQR30n-w"/>)
	mad.push(<StaffPerson name="Amoolya Tadepalli" title="VP of MAD CAP" />)
	mad.push(<StaffPerson name="Namrata Venkatesan" title="VP of ES" image="https://media.licdn.com/dms/image/D5603AQEQzveSKugTAg/profile-displayphoto-shrink_400_400/0/1673501885287?e=1679529600&v=beta&t=RHFYyM3dD4luzfcm9Xc62RdLTqxDxkvJKAQBxd0Y9TY"/>)
	// mad.push(<StaffPerson name="Chloe Lee" title="Emotional Support" />)
	//mad.push(<StaffPerson name="Amoolya Tadepalli" title="VP of MAD CAP and Sugondese Department Head" />)
	eo.push(<StaffPerson name="Aryo Garakani" title="Finance Lead" image="https://media.licdn.com/dms/image/C5603AQGD4UBIRzoyXg/profile-displayphoto-shrink_400_400/0/1663134324511?e=1679529600&v=beta&t=ofjDQSkmJdCghuydNZ0PyUnoeVeFvuVdoGKQPpSr334"/>);
	eo.push(<StaffPerson name="Paige Elliott" title="Outreach Lead" image="https://media.licdn.com/dms/image/D4E03AQEEcrbgwbmQAg/profile-displayphoto-shrink_400_400/0/1670730444758?e=1679529600&v=beta&t=iG5zgzwVB_0YxJuv3Jdb6Cg4i3V4-P35ui4fez5O2Ok"/>)
	eo.push(<StaffPerson name="Rithik Duvva" title="Business Lead" image=""/>)
	eo.push(<StaffPerson name="Anvitha Mahankali " title="Operations & HR Lead" image="https://media.licdn.com/dms/image/D5603AQE2hpcPdgEbbA/profile-displayphoto-shrink_400_400/0/1669544571811?e=1679529600&v=beta&t=z4S-kzdNn1QMgvhbo_dg9NSuQvHckvkAtOjS4xFUr0I"/>)


  return (
    <>
		 	<PageHeader title="Leadership" subtitle="Meet the leadership behind Neurose." />
		 	<div className="team">
				<div className="leftwrap">
					<h1 className='heading gradtext thinfont'>We're ready to serve you with industry-leading experience</h1>
				</div>

				<div className="wrapper">
					<div className="department">
						<h2 className="subheading ">Executive Board</h2>
						<div className='row'>
							{exec}
						</div>
						
					</div>
					<div className="department">
						<h2 className="subheading ">Research Leads</h2>
						<p className="subsubheading italic thinfont">The Research team conducts novel neuroscience research to develop state-of-the-art healthcare solutions. </p>
						<div className='row'>
							{research}
						</div>
					</div>
					<div className="department">
						<h2 className="subheading ">Model & Application Development Leads</h2>
						<p className="subsubheading italic thinfont">The MAD team leverages cutting-edge technologies and the results from the Research team to develop Machine Learning Pipelines and Front-End Applications.</p>
						<div className='row'>
							{mad}
						</div>
					</div>
					<div className="department">
						<h2 className="subheading ">Enterprise Operations Leads</h2>
						<p className="subsubheading italic thinfont">Enterprise Operations performs business-critical tasks to support the organization and conducts outreach and publicity events. </p>
						<div className='row'>
							{eo}
						</div>
					</div>
				</div>


			</div>

    </>
  );
}

const StaffPerson = (props) => {
	return(<>
		<div className="staffperson">
			<div className="img">
				<div className="maxsizeimgwrapper">
					<img src={(props.image && props.image.length > 0) ? props.image : require('../../assets/DefaultPFP.jpeg')} alt="placeholder" className="maxsizeimg" style={{borderRadius: "1rem 1rem 0rem 0rem"}}/>
				</div>
			</div>
			<div className='content'>
				<h2 className="name">{props.name}</h2>
				<h3 className="title">{props.title}
				
				</h3>

				<div className="emailwrapper" >
					<a href={"mailto:" + props.name.split(" ")[0] + "@neuro-se.org"} className="contactbtn btn" ><i className="fa fa-envelope"></i> Contact</a>
				</div>
			</div>
		</div>
	</>)
}

export default Team;