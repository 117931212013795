
import React, { useState } from 'react';
import '../../css/partner.css';
import PageHeader from './../Util/PageHeader';
import GradButton from './../Util/GradButton';
const Partners = (props) => {


	let collegenames = ['hms.png', 'princeton.png', 'yale.png', 'Northwestern.jpeg', 'northeastern.png', 'mit.png', 'upenn.png']
	let links = ["https://hms.harvard.edu/", "https://www.princeton.edu/", "https://www.yale.edu/", "https://www.feinberg.northwestern.edu/", "https://www.northeastern.edu/", "https://www.mit.edu/", "https://www.upenn.edu/"]
	let colleges = []

	let extra = [
		{
			"name": "Harvard Student Agencies",
			"img": "https://assets.website-files.com/5b2d564ed4bf81064e095701/5b314b940e3c09c32304c098_HSA_Logo%20(2).png",
			"link": "https://www.hsa.net/"
		},
		{
			"name": "University of North Texas",
			"link": "https://www.unt.edu/?gclid=Cj0KCQiAt66eBhCnARIsAKf3ZNE8TJh2L02A0PfGN1p6SCRnvvtt8b6IEskLnSjMAxEJLwjHsqm_QesaAl6zEALw_wcB",
			"img": "https://identityguide.unt.edu/sites/default/files/lettermark_one-line_diving_eagle_green_0.png"
		},
		{
			"name": "University of Texas at Austin",
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/University_of_Texas_at_Austin_logo.svg/2560px-University_of_Texas_at_Austin_logo.svg.png",
			"link": "https://www.utexas.edu/"
		},
		{
			"name": "University of Texas at Dallas",
			"img": "https://sandbox.utdallas.edu/brand/files/2022/04/monogram-emblem-border-880-1.png",
			"link": "https://www.utdallas.edu/"
		},
		{
			"name": "Eye Level",
			"img": "https://storage.googleapis.com/enty/5ffc1ce6-a9a9-4118-b810-a25733a91c29.jpg",
			"link": "https://www.myeyelevel.com/US/index.do"
		},
		{
			"name": "Raytheon Technologies",
			"img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Raytheon_Technologies_logo.svg/2560px-Raytheon_Technologies_logo.svg.png",
			"link": "https://www.rtx.com/"
		},
		{
			"name": "Gene Haas",
			"img": "https://gabriels-house.org/wp-content/uploads/2022/07/geen-haas-1024x1024.jpg",
			"link": "https://ghaasfoundation.org/"
		},
		{
			"name": "Intel",
			"img": "https://1000logos.net/wp-content/uploads/2017/02/Intel-Logo-2005.png",
			"link": "https://intel.com/"
		},
		{
			"name": "Regeneron Pharmaceuticals",
			"img": "http://photos.prnewswire.com/prnfull/20160801/394535LOGO",
			"link": "https://www.regeneron.com/"
		}
	]

	for(var i = 0; i < collegenames.length; ++i) {
		colleges.push(
			<div className="col-12 col-md-6">
				<a href={links[i]} target="_black" className="collegeimages ">
					<img src={require(`../../assets/colleges/${collegenames[i]}`)} className="collegeimg"></img>	
				</a>
			</div>
		)
	}
		for(var i = 0; i < extra.length; ++i) {
			colleges.push(
			<div className="col-12 col-md-6">
		 		<a href={extra[i].link} target="_black" className="collegeimages ">
		 			<img src={extra[i].img} className="collegeimg"></img>	
		 		</a>
			</div>
		)
	}
	

  return (
	
    <div className="partners" style={{width: "100%"}}>
		<PageHeader title="Partners & Recognition" subtitle="Our Partners, News Recognition, and awards." />
		<div className="sec1">
			<div className='regwrapper'>
				<h2 className="headinggrad">Supported by World Class Institutions</h2>
				
				<h2 className="headingSmall">Partners</h2>
				<p className="subheading">Neurose is partnered or working with leading labs, professors, and teams at many of the universities and corporations below.</p>
				<br />
				<h3 className="subheading">Direct Partners Labs</h3>
				<br></br>
				<div className="col-12 col-md-6">
					<a href="https://medicine.stanford.edu/" target="_black" className="collegeimages">
						<img src={require(`../../assets/colleges/Stanford.png`)} className="collegeimg"></img>		
					</a>
				</div>
				<br></br><br></br>
				<h3 className="subheading">Supporting Partner Affiliations Labs & Corporations</h3>
				<br></br>
				<div className="row">
						{colleges}
					 </div>
			</div>
		</div>
		
		<div className="sec2">
			<div className='sec2inneractionwrapper'>
				<h2 className="headinggrad">Work with us</h2>
				<h2 className="headingSmall">Become a Partner</h2>
				<br />
				<p className="subheading2">
					We are always looking for new and innovative partners to join our growing network.
				</p>
				<br />
				<GradButton link="mailto:partner@neuro-se.org?subject=Neurose Partnership" text="Let's Work Together" />
			</div>
		</div>

    </div>
  );
}


export default Partners ;